.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






h1 {
  font-family: 'Glossier', sans-serif;
}


body {
  font-family: 'Glossier', sans-serif;
  /*background: #fef8f3;*/
  /*background-color: #fef8f3;*/
  color: black;
}


.font-tiskani {
  font-family: 'Glossier', sans-serif;
}

.font-medium {
  font-family: Glossier-Medium, sans-serif;
}

.font-regular {
  font-family: Glossier-Regular, sans-serif;
}

.font-pro {
  font-family: Glossier-Pro, sans-serif;
}

.font-pro-light {
  font-family: Glossier-Pro-Light, sans-serif;
}

div {
  /*background-color: #fef8f3;*/
}

.font-tiskani-mali {
  /*font-family: 'Futura LT Paneuropean', sans-serif;*/
  font-family: 'Glossier', sans-serif;
  font-size: small;
}

.font-tiskani {
  /*font-family: 'Futura LT Paneuropean', sans-serif;*/
  /*font-family: 'apercu 2', sans-serif;*/

}

#font-tiskani {
  /*font-family: 'Futura LT Paneuropean', sans-serif;*/
  /*font-family: 'apercu 2', sans-serif;*/
  font-size: small;
}

.font-pisani{
  font-family: 'Linotype Didot', sans-serif;
  font-size: xx-large;
}

#font-pisani{
  font-family: 'Linotype Didot', sans-serif;
  font-size: xx-large;
}

/*body {*/
/*  font-family: 'Futura LT', sans-serif;*/
/*}*/

h1, h2, h3 {
  font-family: Glossier, sans-serif;
}

.container-center {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.background-white {
  background-color: #ffffff;
}