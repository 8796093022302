.payment-details-container {
    text-align: center; /* Center align the content */
    margin: auto; /* Center the container horizontally */
    max-width: 600px; /* Set maximum width */
    margin-bottom: 5rem;
}

.naplata-container {
    padding: 3rem;
}


.payment-details-table {
    width: 100%; /* Set table width to 100% */
    border-collapse: collapse; /* Collapse table borders */
}

.payment-details-table td {
    padding: 8px; /* Add padding to table cells */
    border: 1px solid #ddd; /* Add border to table cells */
    text-align: left; /* Align text to the left within table cells */
}

.payment-details-table strong {
    font-weight: bold; /* Apply bold font weight to strong tags */
}

@media (min-width: 600px) {

    .padding-price {
        padding-right: 1rem;
    }
}

@media (min-width: 800px) {

    .padding-price {
        padding-right: 2rem;
    }
}

@media (min-width: 1025px) {
    .naplata-container {
        padding: 3rem 3rem 5rem 10rem;

    }

    .padding-price {
        padding-right: 3rem;
    }
}



@media (max-width: 1025px) {
    .naplata-container {
        font-size: 0.9rem;
    }

    .payment-details-container {
        padding: 1rem;
    }
}

@media (max-width: 600px) {
    .font-tiskani-mali {
        font-size: small;
        font-family: Glossier, sans-serif;
    }
}