

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.btn-cart {
    padding: 0;
    border: none;
    background: none;
}

.font-weight-normal {
    font-weight: normal;
}

.font-size-cart {
    font-size: 0.9rem;
}

.product-name-container {
    margin-right: 1rem;
}

.price-btn-text-top {
    display: inline-flex;
    align-items: flex-start; /* Align text to the top */

}