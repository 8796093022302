/* Container for filter and sort */

.filter-menu-btn {
    background-color: inherit;
    border: none;
    color: grey;
}

.close-button-filter {
    background: none;
    padding: 0.5rem 0rem;
    border: none;
}

.category-headline {
   font-size: small;
    font-family: Glossier-Regular, sans-serif;
    padding: 0.5rem 0;
}

#modal-header {
    border-bottom: 1px lightgrey;
    /*padding: 0 0.2rem;*/
}

.filter-sort-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}

/* Filter container */
.filter-sort-container {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-family: Glossier, sans-serif;
    font-size: 0.9rem;
}
.sort-container-wrapper {
    position: relative;
    display: inline-block;
    width: auto; /* Set to auto initially */
    margin: 0 1rem;
    font-size: 0.9rem;
}

.sort-button {
    background-color: transparent;
    border: none;
    color: grey;
    cursor: pointer;
    padding: 5px;
    width: 100%; /* Ensure the button matches the container width */
}

.sort-button:hover {
    color: black;
}

.sort-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1;
    min-width: 200px; /* Set a minimum width */
}

.sort-dropdown div {
    padding: 10px;
    cursor: pointer;
    color: black;
    white-space: nowrap; /* Prevent text wrapping */
}

.sort-dropdown div:hover {
    background-color: #f1f1f1;
}

/* Add any additional styles needed for your existing elements */


.filter-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Adjust width as needed */
    background-color: white;
    border: 1px solid #ccc;
    /*border-radius: 4px;*/
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /*padding: 1rem;*/
    /*padding-top: 0;*/
    z-index: 1001;
}

.filter-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.close-button {
    background: none;
    padding: 0.5rem 1rem;
    border: none;
}

.close-button:hover {
    border: 1px dashed grey;
}

.categories {
    display: flex;
    gap: 5px;
}

.category-button {
    background-color: #eae9e9;
    border: none;
    /*border-radius: 4px;*/
    padding: 0.5rem 1rem;
    font-family: Glossier-Italic-Light, sans-serif;
    font-size: small;
    cursor: pointer;
}

.category-button.active {
    /*background-color: grey;*/
    border: 1px solid black;
}

.price-range {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.price-input {
    width: 80px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    /*border-radius: 4px;*/
    -moz-appearance: textfield; /* Firefox */
}

.price-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Remove margin in Chrome */
}

.price-input {
    font-family: Glossier-Regular, sans-serif;
    /*padding: 0 1rem;*/
    font-size: 0.9rem;
}

.price-input:focus {
    outline: none;
    border: 1px solid grey; /* Remove border on focus */
}

.price-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Remove margin in Chrome */
}


.search-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    margin-top: 0.5rem;
}

.search-input:focus {
    outline: none;
    border: 1px solid grey; /* Remove border on focus */
    border-radius: 0;
}

.search-input::-webkit-outer-spin-button,
.search-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Remove margin in Chrome */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Grey background with transparency */
    z-index: 1000;
}

.filter-buttons {
    display: flex;
    width: 100%;
}

#apply-cancel-btn {
    width: 100%;
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    font-family: Glossier-Regular, sans-serif;
    font-size: 0.875rem;
    letter-spacing: .001875em;
    margin: 0.2rem;
}

#apply-cancel-btn:hover {
    border: 1px solid black;
}

.apply-btn, .cancel-btn {
    width: 50%; /* Each button covers half of the modal's width */
    padding: 0.5rem 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .filter-menu-btn {
        font-size: small;
    }

    .sort-container-wrapper {
        font-size: small;
    }

    .filter-modal {
        padding: 0 1rem;
    }
}