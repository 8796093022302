
.font-tiskani {
    font-family: Glossier, sans-serif;
}

.font-tiskani-kontakt {
    font-size: medium;
}

.font-italic {
    font-family: Glossier-Italic, sans-serif;
}

.font-large {
    font-size: large;
}

.big-container-contact {
    padding: 10%;
    /*display: flex;*/
    /*background: #Faede8;*/
    margin-top: 0;
    margin-bottom: 10rem;
    background-color: #fef8f3
}

.no-border {
    border: 0;
}
.side-by-side {
    display: inline-block;
    width: 50%; /* Adjust the width as needed */
    /*justify-content: center;*/
    /*background: #Faede8;*/
    /*margin: 5px; !* Add margin for spacing *!*/
    /*background-color: #e0e0e0;*/
    /*padding: 10px;*/
}

.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background: #Faede8;*/
}

.centered-container > div{
    /*background: #Faede8;*/
}

.padding-down {
    padding-bottom: 7%;
}

.padding-15 {
    padding-bottom: 15%;
}

.img-max {
    max-height: 100%;
    max-width: 100%;
}

/*.slika-container {*/
/*    padding: 7%;*/
/*    padding-left: 30px !important;*/
/*    margin-top: 10%;*/
/*}*/


@media only screen and (max-width: 800px) {

    .centered-container{
        padding-top: 3rem;
    }

    .font-smaller {
        font-size: 1.5rem;
    }
    .big-container{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

    }

    .side-by-side{
        display: block;
        width: 100%;
    }

    .padding-15 {
        padding-bottom: 0;
    }

    .image-container{
        max-width: 100%;
        margin: 4%;
        }

    .img-container{
        /*max-width: 100%;*/
        /*margin: 4%;*/
        padding-top: 3rem;
    }

    .trnsko-container{
        font-size: smaller;
        margin: 0;
        margin-bottom: 10%;
    }
    .lmi-container{
        font-size: smaller;
        margin: 0;
        margin-bottom: 10%;
    }

    .naslov {
        font-size: 15px;
    }
}

@media only screen and (min-width: 800px) {

    .big-container{
        padding-left: 0;
        padding-right: 0;
        display: flex;
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 0;
    }

    .side-by-side{
        margin: 0
    }

    .image-container {
        width:50%;
        height:100%
    }

    .contact-container {
        height: 100%;
    }

    .padding-15 {
        padding-bottom: 0;
    }


    /*.big-container {*/
    /*    display: flex;*/
    /*}*/
    .image-container{
        max-width: 50%;
        align-self: center;
    }

    .big-container-contact {
        display: flex;
    }


}


