.align-middle {
    vertical-align: middle;
    text-align: center;
    padding: 10px; /* Adjust the padding value as needed */

}

.filter-div {
    margin: 7px;
}

@media screen and (max-width: 540px) {
    /* Adjust font size and other styles for smaller screens */
    .font-tiskani-order {
        font-size: 9px; /* Adjust font size as needed */
    }

    .padding {
        padding: 0;
    }

    /* Add other styles for smaller screens */
}
