@font-face {
    font-family: 'Linotype Didot';
    src: local('Linotype Didot Pro Italic'), local('Linotype-Didot-Pro-Italic'),
    url('./DidotLTPro-Italic/DidotLTPro-Italic.woff2') format('woff2'),
    url('./DidotLTPro-Italic/DidotLTPro-Italic.woff') format('woff'),
    url('./DidotLTPro-Italic/DidotLTPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'apercu 2';
    src: local('Apercu Regular.otf'), local('Apercu Regular.otf'),
    url('./Apercu Font Family/Apercu Medium.otf') format('otf'),
    url('./Apercu Font Family/Apercu Regular.otf') format('otf'),
    url('./Apercu Font Family/Apercu Italic.otf') format('otf');
    font-weight: normal;
    /*font-style: italic;*/
}

/*@font-face {*/
/*  font-family: 'Linotype Didot';*/
/*  src: local('Linotype Didot Pro Italic'), local('Linotype-Didot-Pro-Italic'),*/
/*  url('fonts/DidotLTPro-Italic/DidotLTPro-Italic.woff2') format('woff2'),*/
/*  url('fonts/DidotLTPro-Italic/DidotLTPro-Italic.woff') format('woff'),*/
/*  url('fonts/DidotLTPro-Italic/DidotLTPro-Italic.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: italic;*/
/*}*/

@font-face {
  font-family: 'apercu';
  src: local('Apercu Light.otf'), local('Apercu Medium.otf'),
  url('./apercu/Apercu Light.otf') format('otf'),
  url('./apercu/Apercu Medium.otf') format('otf'),
  url('./apercu/Apercu Italic.otf') format('otf');
  /*font-weight: normal;*/
  /*font-style: italic;*/
}

@font-face {
    font-family: 'Glossier';
    src: url('./apercu/Apercu Light.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Mono';
    src: url('./apercu/Apercu Pro Mono.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Italic';
    src: url('./apercu/Apercu Italic.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Italic-Light';
    src: url('./apercu/Apercu Light Italic.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Regular';
    src: url('./apercu/Apercu Regular.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Pro';
    src: url('./apercu/Apercu Pro Regular.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Pro-Light';
    src: url('./apercu/Apercu Pro Light.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}

@font-face {
    font-family: 'Glossier-Medium';
    src: url('./apercu/Apercu Medium.otf') format('truetype');
    /* Add additional src lines for other font formats if needed */
}
