.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.content {
    flex: 0 0 calc(50% - 10px); /* Adjust the width as needed */
    margin-bottom: 20px;
}

img {
    max-width: 100%;
    height: auto;
}

/* Media query for mobile devices */
@media screen and (max-width: 767px) {
    .container {
        flex-direction: column;
    }

    .content {
        flex: 1 0 auto;
    }
}
