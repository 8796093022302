
.dropdown:hover .dropdown-menu {
    display: block;
}

.top-banner {
   background-color: #939393;
}

.top-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Adjust as needed */
}

.top-banner.show {
    display: block;
}

.container-banner {
    justify-content: center;
}

.banner-text {
    text-align: center;
    font-family: 'Glossier-Pro-Light', sans-serif;
    padding: 0.2rem;
    font-size: 0.9rem;
    color: white;
}

.container {
    /*margin: 30px;*/
    display: flex;
    justify-content: center;

}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.navbar-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*padding-top: 3rem;*/
    /*background-color: rgba(255, 255, 255, 0.6); !* Apply opacity to the background *!*/
    /*background-color: #fef8f3;*/
    /*opacity: 0.6; !* Adjust opacity as needed *!*/
    z-index: -1; /* Ensure the background is behind the content */
}

.navbar-content {
    position: relative;
    z-index: 1; /* Ensure the content is above the background */
    /* Add styles for navbar content */
}

/* Additional styles for navbar items, logo, etc. */


.nav-item-not-dropdown {
    padding-top: 1px;
}

#navbar {
    position: fixed;
    color: black;
}

.font-navbar {
    font-size: 1.2em;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Glossier-Pro', sans-serif;

}

.button-margin {
    margin-left: 30px;
    margin-right: 30px
}

.logo-img {
    /*width: 20rem;*/
    /*height: 10rem;*/
    max-width: 100%;
    max-height: 100%;
}

.logo-div {
    width: 10rem;
    height: 5rem;
    text-align: center;
}

/*.logo-div {*/
/*    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*}*/

/*.logo-div.show {*/
/*    opacity: 1;*/
/*    visibility: visible;*/
/*}*/

.cart-icon {
    height: 1.8rem; /* Adjust the height of the cart icon as needed */
    width: auto;
    margin-top: -5px; /* Adjust the margin to vertically align the icon with text */
    float: right;
    margin-right: 5rem;
}

.grandparent {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    /*height: 100vh;*/
}

.parent {
    /*width: 80%; !* Adjust the width of the parent container as needed *!*/
    display: flex;
    text-align: center;
    flex: 1;
}

.centered {
    /*max-width: 600px; !* Adjust the width as needed *!*/
    margin: 0 auto;
    text-align: center;
}

.right-content {
    /*flex: 1; !* Take up remaining space in the parent *!*/
    /*text-align: right;*/
    /*padding-right: 20px; !* Optional: add padding for spacing *!*/
    /*margin-left: auto;*/
}

.left-content {
    /*flex: 1; !* Take up remaining space in the parent *!*/
    /*text-align: right;*/
    /*padding-right: 20px; !* Optional: add padding for spacing *!*/
    /*margin-right: auto;*/
    float: left;
}

.left, .right {
    /*width: 20%; !* Adjust as needed *!*/
    padding: 20px;
    /*border: 1px solid #ccc;*/
    box-sizing: border-box;
}

.navbar.scrolled {
    /*background-color: rgba(255, 255, 255, 0.9); !* Change the background color here *!*/
    background-color: #fef8f3;
    opacity: 80%;
    transition: background-color 0.3s ease; /* Add transition for smooth effect */
}



.center {
    /*width: 50%; !* Adjust as needed *!*/
    padding: 20px;
    /*border: 1px solid #ccc;*/
    box-sizing: border-box;
    text-align: center;
}

.justify-content-center{
    justify-content: center;
}

.hidden {
    visibility: hidden
}

.btn-custom {
    background-color: transparent;
    margin-bottom: 0;
    border: none;

}

#nav-link {
    color: black;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Glossier-Pro', sans-serif;
}

.nav-link li  {
    text-decoration:none;
}
.nav-link:hover {
    text-decoration: underline;
    text-decoration-color: black;
    text-underline-offset: 2px;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;

}

.dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

/* Set the background image for the hero section */
.hero-section {
    position: relative;
}

/* Position the navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the navbar is above the background image */
    /* Additional styles for your navbar */
}


.košarica {
    margin-right: 1rem;
}




@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex;
        flex-basis: 100%; /* This will override flex-basis: auto; */
        /* Additional styles can be added here if needed */
    }




    /* Add more specific styles as needed */
}


@media (min-width: 500px) {
    .košarica {
        margin-right: 3rem;
    }
}

@media (min-width: 401px) and (max-width: 1100px) {

    #nav-link {
        font-size: 1.1rem;
    }
}

@media (max-width: 992px) {
    .navbar-collapse.collapsing {
        justify-content: flex-start !important; /* Align items to the start */
    }

    .justify-content-center {
        justify-content: flex-start !important; /* Align items to the start */
        /*margin-left: 3em;*/
    }

    .navbar-collapse {
        margin-top:15em;
    }

    .navbar-brand {
        margin-left: auto; /* Push the logo to the right */
        margin-right: auto; /* Push the logo to the left */
        text-align: center; /* Center the text */
    }

    .navbar-toggler {
        order: -1; /* Change the order to display the toggler on the left */
        transition: none; /* Disable transitions for the toggle button */
        margin: 0 1.5rem;
    }

    .grandparent {
        justify-content: space-between;
    }

    .navbar-collapse {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out; /* Adjust the duration and easing as needed */
    }

    .navbar-collapse.show {
        max-height: 500px; /* Adjust the maximum height as needed */
    }

    /* Create a pseudo-element to serve as the background */
    /* Create a pseudo-element to serve as the background */
    .navbar-bg-big::before {
        content: "";
        position: absolute;
        top: -20px; /* Adjust this value as needed to cover the lower element */
        left: 0;
        width: 100%;
        height: calc(100% + 16em); /* Adjust this value to cover the lower element */
        /*background-color: rgba(255, 255, 255, 0.6); !* Apply opacity to the background *!*/
        /*background-color: rgba(254, 248, 243, 0.8);*/
        background-color: white;
        opacity: 0.7;
        z-index: -1; /* Ensure it's behind other content */
    }

}





