.order-container {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    /*height: 100vh; !* Set height to 100% of viewport height *!*/
}

.font-tiskani-order-page-text {
    font-size: medium;
}

.order {
    padding: 8rem 5rem;
    /*font-size: medium;*/
}


.font-tiskani-order-page {
    font-family: Glossier, sans-serif;
    font-size: medium;
}

.section {
    padding: 2rem 0; /* Add vertical padding between sections */
}

.order-items-table {
    width: 100%;
    border-collapse: collapse;
}

.order-items-table th,
.order-items-table td {
    padding: 8px; /* Add padding between cells */
    text-align: center; /* Center align content within cells */
    border: 1px solid #ddd; /* Add border for cells */
}

#btn-paid {
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    font-family: Glossier, sans-serif;
    font-size: small;
    /*margin-top: 1rem;*/
    padding: 5px 10px;
    margin: 1rem;
}

#btn:hover {
    border: 1px solid black;
}



@media screen and (max-width: 800px) {
    /* Adjust font size and other styles for smaller screens */
    .font-tiskani-order-page {
        font-size: small; /* Adjust font size as needed */
    }

    .font-tiskani-order-page-text {
        font-size: small;
    }

    .padding-order-page-text {
        padding: 2rem;
    }
}


@media screen and (max-width: 540px) {
    /* Adjust font size and other styles for smaller screens */
    .font-tiskani-order-page {
        font-size: 11px; /* Adjust font size as needed */
    }

    .padding {
        padding: 0;
    }

    /* Add other styles for smaller screens */
}