
.small-images-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px; /* Adjust padding as needed */
}

.sold-out {
    color: gray;
}


.small-image {
    width: 5rem; /* Adjust the width of the small images */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Adjust margin between images */
    cursor: pointer; /* Show pointer cursor on hover */
}

.small-image:hover {
    opacity: 0.7; /* Reduce opacity on hover */
}


.relative-container {
    position: relative;
}

.arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #808080; /* Gray color */
    cursor: pointer;
}

.left-arrow {
    left: 10px; /* Adjust as needed */
}

.right-arrow {
    right: 10px; /* Adjust as needed */
}



.display-flex-div {
    display: flex;
}

.flex-grow-div {
    flex-grow: 1; /* Allow content to grow to fill available space */
}

.text-container {
    margin: 5rem;
    background-color: white;
}

.pics-desc-container {
    margin: 5rem;
}

.pic-container {
    
}

.product-img {
    width: 20rem;
    height: 20rem;
    /*object-fit: cover;*/
    object-fit: contain;
}

.uppercase {
    text-transform: uppercase;
}


.naslov {
    font-size: xx-large;
    margin-bottom: 0.5rem;

}

.cijena {
    font-size: larger;
}

.quantity_label{
    margin-top: 1rem;
    /*font-family: Glossier, sans-serif;*/
}

.button-add {
    width: 100%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    /*grid-template-columns: repeat(2, 1fr); !* Two columns *!*/
    gap: 10px; /* Adjust the gap between images */
    margin-top: 20px; /* Adjust top margin as needed */
}

.quantity {
    margin-bottom: 1rem;
    width: 4rem;
    height: 2rem;
    margin-top: 0.2rem;
}

.short-description {
    font-size: large;
    margin: 3rem 0 1rem 0;
}

.grid-container-small {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-gap: 10px; /* Adjust the gap between images as needed */
}

.small-image2 {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Show pointer cursor on hover */
}

.small-image2:hover {
    opacity: 0.7; /* Reduce opacity on hover */
}

.sale-bg {
    background-color: #f5f4f4;
    display: inline-block; /* Make the wrapper behave like an inline-level element */
    /*border: 1px dashed black;*/
    padding: 5px 10px;
    font-family: Glossier-Mono, sans-serif;
    font-size: small;
    font-weight: lighter;
    color: #4b4a4a;

}

@keyframes moveDottedBorder {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 0;
        right: 0;
    }
    50% {
        bottom: 0;
        right: 0;
    }
    75% {
        bottom: 0;
        left: 0;
    }
    100% {
        top: 0;
        left: 0;
    }
}


#btn-add-to-cart {
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    /*font-family: Glossier, sans-serif;*/
    font-family: Glossier-Pro, sans-serif;
    font-size: small;
    width: 15rem;
    height: 2.5rem;
}

#btn-add-to-cart:hover {
    border: 1px solid black;
}

.kratki-opis {
    margin-bottom: 1.5rem;
    /*font-family: Glossier, sans-serif;*/
    font-size: medium;
    color: #737373;
}

.brand {
    /*font-family: Glossier, sans-serif;*/
    font-size: large;
}

.opis {
    margin-bottom: 1.5rem;
    font-family: Glossier, sans-serif;
    font-size: medium;
}


.column {
    flex: 1;
    padding: 20px;
    /*text-align: center;*/
}

.column img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}


/*za velike ekrane*/
@media screen and (min-width: 1100px) {
    .big-container {
        display: flex;
    }

    .pics-desc-container {
        width: 50%
    }

    .text-container {
        width: 50%
    }

    .container-product-page {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .big-container {
        /*background-color: white;*/
        margin:0;
        padding: 0 5rem;
    }

    .padding-top-navbar {
        padding-top: 10rem;
    }

    .display-flex-div {
        margin-left: 3rem;
    }

    .small-image {
        width: 15rem; /* Adjust the width of the small images */
        height: auto; /* Maintain aspect ratio */
        margin-bottom: 10px; /* Adjust margin between images */
        cursor: pointer; /* Show pointer cursor on hover */
    }
}

/*za velike ekrane*/
@media screen and (max-width: 1100px) {
    .big-container {
    }

    .container-product-page {
        display: block;
    }

    .padding-top-navbar {
        padding-top: 4rem;
    }
}

@media screen and (min-width: 680px) and (max-width: 1025px) {
    .col img {
        max-width: 85%;
    }

    /*.small-image {*/
    /*    width: 5rem;*/
    /*}*/
}
