.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Make the container take up the full height of the viewport */
    margin: 20px; /* Add margin around the container */
}

.login-form {
    width: 300px; /* Set a fixed width for the form */
    margin-top: 20px; /* Add margin to the top of the form */
}

.login-form div {
    margin-bottom: 10px; /* Add margin to the bottom of each form field */
}

.login-form label {
    margin-right: 10px; /* Add margin to the right of each label */
}

.login-form button {
    margin-top: 10px; /* Add margin to the top of the submit button */
}
