.modal-headline {
    font-family: Glossier-Regular, sans-serif;
    padding: 0 1rem;
    font-size: 0.9rem;
}



.flex-space-between {
    display: flex;
    justify-content: space-between;
}


.font-size-card {
    font-size: 0.875rem;
}

.font-add-to-basket {
    letter-spacing: .001875em;
}

.card-text {
    font-size: 14px; /* Adjust font size as needed */
    line-height: 1.5; /* Adjust line height for readability */
    height: 3em; /* Set a fixed height for two rows */
    overflow: hidden; /* Hide any overflow content */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit text to two lines */
    -webkit-box-orient: vertical;
}


#cards {
    padding: 1rem;
    background: #fff;
    border: none;
}

.font-colour-grey {
    color: #737373;
}

h1 {
    text-align: center;
}

p {
    margin-bottom: 0;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-top {
    margin-top: 0.3rem;
}

#btn {
    width: 100%;
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    font-family: Glossier-Regular, sans-serif;
    font-size: 0.875rem;
    letter-spacing: .001875em;
}


.discount-label {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); /* White color with 80% opacity */
    color: black; /* Change text color as needed */
    padding: 5px; /* Adjust padding as needed */
    border: 1px black dashed;
    /*border-radius: 5px; !* Optional: Add border radius for rounded corners *!*/
    font-size: 0.8rem; /* Adjust font size as needed */
}


#btn:hover {
    border: 1px solid black;
}

#card-body {
    padding: 0;
    border:none;
}

.product-img {
    width: 20rem;
    height: 20rem;
    object-fit: cover;
}

@media (min-width: 768px) {
    .div_zauzeto {
        margin-bottom: 3rem;
        font-size: 2.5rem;
        color: #c41313;
    }
}

@media (max-width: 768px) {
    .div_zauzeto {
        margin-bottom: 3rem;
        font-size: 1.5rem;
        color: #c41313;
    }
}

.udruge {
    color: rgb(0, 179, 60);
}

.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    /*max-width: 1120px;*/
    width: 100%;
    margin: 0 auto;

}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.cards__wrapper {
    position: relative;
    /*margin: 50px 0 45px;*/
}

#card {
    margin: 10px 10px;
    border: none;
}

.button-container {
    display: inline;
    align-content: space-between;
    margin: -30px;
}

.group-margin {
    margin: 10px;
}

.padding {
    padding: 5rem 2rem 5rem 2rem;
}

.dark-colour {
    background-color: #08376b;
}

@media only screen and (max-width: 1300px) {
    .grid-container {
        grid-template-columns: auto auto auto;
    }
}

@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }


}

@media screen and (max-width: 1000px) {
    .grid-container {
        display: grid;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 700px) {
    .grid-container {
        display: grid;
        grid-template-columns: auto;
    }

    .cards {
        padding: 3rem;
    }

    .group-margin {
        margin: 5px;
    }
}


@media (min-width: 768px) {
    .modal-xl-moj {
        width: 100%;
        max-width: 1200px;
    }
}

.pdf-container {
    margin-left: 10%;
}

.free-label {
    padding: 3px;
}

.text-btn {
    background-color: white;
    border: none;
    padding: 0;
}

.padding-right {
    padding-right: 2rem;
}

.strike-through-text {
    text-decoration: line-through;
}

.dark-grey-text {
    color: #737373; /* CSS color name for dark grey */
}


     /*  Rollover image styles  */
 .figure {
     position: relative;
     /*width: 360px; !* can be omitted for a regular non-lazy image *!*/
     /*max-width: 100%;*/
 }
.figure img.Sirv.image-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    opacity: 0;
    transition: opacity .2s;
}
.figure:hover img.Sirv.image-hover {
    opacity: 1;
}
