

.carousel-item {
    transition: transform 1s ease; /* Adjust the duration and easing as needed */
}

.white-background {
    /*background-color: white;*/
}

.naslov-istaknuti-proizvodi {
    /*margin-left: 10%;*/
    padding: 1rem;
    font-size: 0.9em;
    font-family: Glossier-Pro, sans-serif;
    text-transform: uppercase;
}

.padding-carousel {
    padding: 2rem;
}