.color {
    background-color: #fef8f3;
}

input[type="radio"] {
    margin-right: 10px;
}

.font-shipping {
    font-size: 0.9rem;
}

.font-tiskani {
    font-family: Glossier, sans-serif;
}

.container {
    justify-content: center;
}
.color-white {
    background-color: white;
}

.padding {
    padding: 1rem;
}


.outer-div {
    text-align: left;
    margin: 5%;
    padding: 0 5%;
}


.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 100vh; !* Adjust the height as needed *!*/
}

.left-aligned-text {
    text-align: left;
}


.veliki {
    font-size: large;
}

.option{
    padding: 1%;
}

#button-pay {
    /*width: 100%;*/
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    font-family: Glossier-Mono, sans-serif;
    font-size: small;
    margin-top: 2rem;
    padding: 0.5rem;
}

#button-pay:hover {
    border: 1px solid black;
}

/*section * {*/
/*    background-color: #fef8f3; !* Set your desired background color for all descendants *!*/
/*}*/

.kolicina-div {
    border: 1px solid #fce1e5;
    padding: 2px
    /*border-radius: 5px;*/
}

.padding-top-navbar {
    /*padding-top: 10rem;*/
    /*margin: 1rem;*/
}

@media (min-width: 800px) {
    .padding-top-navbar {
        padding-top: 10rem;
    }
    /*.h-custom {*/
    /*    height: 100vh !important;*/
    /*}*/
}

@media (max-width: 600px) {
    .container-cart-1 {
        /*padding: 2rem;*/
    }

    .padding-top-navbar {
        padding-top: 5rem;
    }
    /*.h-custom {*/
    /*    height: 100vh !important;*/
    /*}*/
}