video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

/*.video-container {*/
/*    width: 80rem; !* Set the desired width for the video *!*/
/*    height: 30rem; !* Set the desired height for the video *!*/
/*    position: absolute;*/
/*    !*position: fixed;*!*/
/*}*/

.video-container {
    /*position: absolute; !* Position the video container within the hero section *!*/
    /*top: 0;*/
    /*left: 0;*/
    position: fixed;
    width: 80%; /* Ensure the video container takes up the full width */
    height: auto; /* Adjust the height as needed */
    /*z-index: -1; !* Set a negative z-index to place the video behind other content *!*/
}

/* Set the background image for the hero section */
.hero-section {
    position: relative;
}

/* Position the navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the navbar is above the background image */
    /* Additional styles for your navbar */
}

.hero-section {
    position: relative;
}

.image-container2 {
    position: relative;
    height: 100vh; /* Set the height to cover the entire viewport */
    /*overflow: hidden; !* Hide overflow to prevent scrolling *!*/

}

.image-container2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    object-position: bottom;

}


.content {
   /*padding-top: 800px*/
}

.hero-section {
    position: relative
}

div {
    /*background-color: white;*/
}

/*.dark-background{*/
/*    background: #08376b;*/

/*}*/

.treatment-container {
    padding: 1rem;
}

.carousel-photo {
    width: 100%
}

.webshop-treatment-container {
    display: flex;
    padding-top: 5rem;
    justify-content: space-between;
}

.webshop-container {
    /*flex-grow: 1;*/
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center;
    position: relative;
    padding: 1rem;


}

.treatment-container {
    /*flex-grow: 1;*/
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center;
    position: relative;
}

.webshop-photo {
    position: relative;
    width: 100%
}

.centered-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15rem;
}


#hero-btn {
    background-color: white;
    border-radius: 0;
    border: 1px solid black;
    border-top-color: white;
    border-left-color: white;
    color: black;
    /*font-family: 'Futura LT Paneuropean', sans-serif;*/
    /*font-family: 'Glossier', sans-serif;*/
    font-family: Glossier-Pro, sans-serif;
    /*font-size: small;*/
    width: 9rem;
}

#hero-btn:hover {
    border: 1px solid black;
}

.carousel-inner-moj {
    position: relative;
    overflow: hidden;
    padding-top: 7%;
}

.margin-carousel {
    margin: 0 0 5rem 0;

}

.carousel-inner-moj::after {
    display: block;
    clear: both;
    content: "";
}

.hero-container {
    /*background: url('../images/naslovna.jpg') center center/cover no-repeat;*/
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .webshop-container {
        width:100%
    }

    .treatment-container {
        width:100%
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn--large {
        width: 100%;
    }

    .webshop-treatment-container {
        display: block;
        width: 100%;

    }

    .treatment-container {
        padding: 1rem;
    }

    .webshop-container {
        padding: 1rem;
    }

    #hero-btn {
        width: 8rem;
    }
}

@media screen and (max-width: 1100px) {
    .image-container2 {
        max-height: calc(100vh - 5rem); /* Adjust the maximum height to leave space for the text at the bottom */
    }
}


@media screen and (min-width: 769px) {
    .carousel-width-margin {
        width: 80%;
        margin-left: 10%;
    }

    .webshop-container {
        width:50%
    }

    .treatment-container {
        width:50%
    }

    .webshop-treatment-container {
        /*margin: 5% 10%;*/
        /*padding: 5rem;*/
    }


}


