
hr {
    opacity: 1;
    color: black;
    margin-bottom: 2px;
}

.plus-btn {
    background-color: transparent;
    border: none;
    /*float: right;*/
}

.tretmani-content {
    opacity: 0;
    transition: opacity 1s ease; /* Add a 0.5s fade-in transition */
}

.tretmani-content.open {
    opacity: 1;
    transition: opacity 1s ease; /* Add a 0.5s fade-in transition */
}

.slide-down {
    animation: slideDown 0.5s ease-in-out;
}

.tretmani-za-lice-container {

}

.font-tiskani {
    font-family: Glossier, sans-serif;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.empty-row {
    height: 20px; /* Adjust the height as needed */
}

.img-text-container {
    display: flex;
}

@media screen and (min-width: 800px) {
    /*.img-text-container {*/
    /*    display: block;*/
    /*}*/

    .img-tretmani-lice {
        margin-left: 2rem;
        padding: 6rem;
    }

    .img-tretmani-tijelo {
        margin-right: 2rem;
        padding: 6rem;
        flex: 1

    /*style={{ flex: "1", marginRight: "20px", padding: "5rem" }}*/
    }

    .tretmani-za-lice-container {
        margin-bottom: 10px;
        padding: 7rem 7rem 0 0
    }

    .tretmani-za-tijelo-container {
        margin-bottom: 10px;
        padding: 7rem 0 0 7rem
    }


}

@media screen and (min-width: 700px) and (max-width: 1300px) {
    /*.img-text-container {*/
    /*    display: block;*/
    /*}*/
    .img-tretmani-lice {
        margin-left: 1rem;
        padding: 3rem;
    }

    .img-tretmani-tijelo {
        margin-right: 1rem;
        padding: 3rem;
        flex: 1

        /*style={{ flex: "1", marginRight: "20px", padding: "5rem" }}*/
    }

    .tretmani-za-lice-container {
        margin-bottom: 10px;
        padding: 4rem 4rem 0 0
    }

    .tretmani-za-tijelo-container {
        margin-bottom: 10px;
        padding: 4rem 0 0 4rem
    }
}

@media screen and (max-width: 800px) {
    .img-text-container {
        display: block;
    }

    .img-tretmani-lice {
        padding: 2rem;
    }

    .img-tretmani-tijelo {
        padding: 2rem;
    }

    .tretmani-za-tijelo-container {
        padding: 2rem;
    }

    .tretmani-za-lice-container {
        padding: 2rem;
    }
}